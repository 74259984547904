<template>
  <div class="container">
    <HeaderComponent />
    <b-breadcrumb class="mb-4">
      <b-breadcrumb-item to="/players">他会選手対策</b-breadcrumb-item>
      <b-breadcrumb-item active>{{ player.last_name }}{{ player.first_name }}</b-breadcrumb-item>
    </b-breadcrumb>

    <b-card class="mb-4 player-card">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h2 class="mb-0">{{ player.last_name }}{{ player.first_name }}</h2>
        <div>
          <b-button variant="outline-secondary" size="sm" @click="showEditPlayerModal">編集</b-button>
          <b-button variant="outline-danger" size="sm" class="ml-2" @click="showDeletePlayerModal">削除</b-button>
        </div>
      </div>
      <b-row>
        <b-col md="6">
          <p class="mb-1">
            <strong>かるた会:</strong>
            {{ player.current_club_name }}
          </p>
          <p class="mb-1">
            <strong>利き手:</strong>
            {{
              player.dominant_hand === "right"
                ? "右"
                : player.dominant_hand === "left"
                ? "左"
                : player.dominant_hand === "other"
                ? "その他"
                : "不明"
            }}
            <!-- player.hand_notesが空でない場合のみ表示 -->
            <template v-if="player.hand_notes">
              <span class="text-muted">（{{ player.hand_notes }}）</span>
            </template>
          </p>
        </b-col>
        <b-col md="6">
          <p class="mb-1 text-muted">
            <small>登録日: {{ formatDate(player.created_at) }}</small>
          </p>
        </b-col>
      </b-row>
    </b-card>

    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="mb-0">コメント・試合結果一覧</h3>
    </div>
    <div class="mb-4 text-right">
      <b-button v-if="!showCommentForm" variant="success" @click="toggleCommentForm">
        <font-awesome-icon icon="comment" />
        コメントを投稿する
      </b-button>
    </div>

    <div v-if="isLoading" class="text-center my-5">
      <b-spinner variant="success" label="Loading..."></b-spinner>
      <p class="mt-2">データを読み込んでいます...</p>
    </div>

    <template v-else>
      <b-collapse id="comment-form" v-model="showCommentForm">
        <b-card class="mb-4">
          <b-alert variant="info" show>
            <p>
              入力のたびに端末に下書きが保存されるので、リロードしても入力内容は消えませんが、メモアプリ等に書き溜めてからコピペして投稿するのを推奨します🙏🙇‍♂️
            </p>
          </b-alert>
          <b-form class="mb-4 pb-2" @submit.prevent="addComment">
            <b-form-group label="コメント内容:" label-for="comment-content">
              <b-form-textarea
                id="comment-content"
                v-model="newComment.content"
                placeholder="コメントを入力してください"
                required
                rows="10"
                @input="saveDraft"
              ></b-form-textarea>
            </b-form-group>
            <div class="d-flex justify-content-end">
              <b-button type="button" variant="secondary" class="mr-2" @click="cancelComment">キャンセル</b-button>
              <b-button type="submit" variant="success">投稿</b-button>
            </div>
          </b-form>
        </b-card>
      </b-collapse>

      <b-list-group v-if="combinedData.length" class="mb-4">
        <b-list-group-item v-for="item in combinedData" :key="item.type + item.id" class="mb-2">
          <template v-if="item.type === 'comment'">
            <h5 class="mt-0">{{ item.poster_name }}</h5>
            <p style="white-space: pre-line">{{ item.content }}</p>
            <small class="text-muted d-block mb-2">
              {{ formatDate(item.created_at) }}
            </small>
          </template>
          <template v-else-if="item.type === 'game'">
            <h5 class="mt-0">{{ item.event_name }}</h5>
            <table class="game-result-table">
              <tbody>
                <tr v-for="game in item.games" :key="game.event_id + game.round">
                  <td class="round">{{ game.round }}回戦</td>
                  <td class="opponent">{{ game.opponent_name }}</td>
                  <td class="result">
                    <span :class="{ win: game.result === 'win', lose: game.result === 'lose' }">
                      {{ game.result === "win" ? "〇" : "●" }}
                    </span>
                    {{ game.score_str }}
                  </td>
                </tr>
              </tbody>
            </table>
            <small class="text-muted d-block mb-2">
              {{ formatDate(item.created_at, "YYYY/MM/DD") }}
            </small>
          </template>
        </b-list-group-item>
      </b-list-group>

      <p v-else>（まだコメント・試合結果がありません。）</p>
    </template>

    <b-modal v-model="isEditCommentModalVisible" title="コメント編集" ok-variant="success" @ok="updateComment">
      <b-form-textarea v-model="editCommentForm.content" placeholder="コメントを編集" rows="3" max-rows="6" required />
    </b-modal>

    <b-modal v-model="isDeleteCommentConfirmVisible" title="削除確認" ok-variant="danger" @ok="deleteComment">
      <p>本当にこのコメントを削除しますか？この操作は元に戻せません。</p>
    </b-modal>

    <b-modal v-model="isEditPlayerModalVisible" title="選手情報の編集" @hide="resetPlayerForm">
      <p>※姓・名を編集したい場合は管理者にご連絡ください。</p>
      <b-form @submit.prevent="updatePlayer">
        <b-form-group label="姓:" label-for="last_name">
          <b-form-input id="last_name" v-model="playerForm.last_name" required :disabled="!isAdmin"></b-form-input>
        </b-form-group>
        <b-form-group label="名:" label-for="first_name">
          <b-form-input id="first_name" v-model="playerForm.first_name" required :disabled="!isAdmin"></b-form-input>
        </b-form-group>
        <b-form-group label="かるた会:" label-for="current_club_name">
          <b-form-input
            id="current_club_name"
            v-model="playerForm.current_club_name"
            list="club-suggestions"
            required
          ></b-form-input>
          <b-form-text small muted>※正式名称を入力してください。例：東京大学かるた会</b-form-text>
          <datalist id="club-suggestions">
            <option v-for="club in clubOptions" :key="club.value" :value="club.text"></option>
          </datalist>
        </b-form-group>
        <b-form-group label="利き手:" label-for="dominant_hand">
          <b-form-select id="dominant_hand" v-model="playerForm.dominant_hand" required>
            <option value="right">右</option>
            <option value="left">左</option>
            <option value="other">その他</option>
          </b-form-select>
        </b-form-group>
        <b-form-group v-if="playerForm.dominant_hand === 'other'" label="利き手に関する補足:" label-for="hand_notes">
          <b-form-input id="hand_notes" v-model="playerForm.hand_notes"></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()">キャンセル</b-button>
        <b-button variant="success" @click="updatePlayer">保存</b-button>
      </template>
    </b-modal>

    <b-modal v-model="isDeletePlayerModalVisible" title="選手の削除" @hide="resetDeletePlayerForm">
      <p>本当にこの選手を削除しますか？この操作は元に戻せません。</p>
      <b-form @submit.prevent="deletePlayer">
        <b-form-group label="パスワードを入力してください:" label-for="delete-password">
          <b-form-input id="delete-password" v-model="deletePlayerPassword" type="password" required></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()">キャンセル</b-button>
        <b-button variant="danger" @click="deletePlayer">削除</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import supabase from "@/supabase";
import HeaderComponent from "@/components/Header.vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import config from "@/config/adminUsersEmail";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "PlayerDetail",
  components: {
    HeaderComponent,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      player: {},
      comments: [],
      newComment: { content: "" },
      editCommentForm: { id: null, content: "" },
      playerForm: {
        last_name: "",
        first_name: "",
        current_club_name: "",
        dominant_hand: "",
        hand_notes: "",
      },
      clubOptions: [],
      selectedUser: null,
      currentUserId: null,
      isEditCommentModalVisible: false,
      isDeleteCommentConfirmVisible: false,
      isEditPlayerModalVisible: false,
      isDeletePlayerModalVisible: false,
      deletePlayerPassword: "",
      isAdmin: false,
      hasDraft: false,
      showCommentForm: false,
      kagetoraData: {},
      combinedData: [],
      isLoading: true,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await this.initializeUser();
      await this.loadPlayerData();
      await this.fetchComments();
      await this.fetchClubs();
      this.mergeData();
      this.loadDraft();
    } catch (error) {
      console.error("Error during initialization:", error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async initializeUser() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        this.selectUser(user.uid);
        this.currentUserId = user.uid;
        this.isAdmin = config.adminUsersEmail.includes(user.email);
      }
    },
    async loadPlayerData() {
      await this.fetchPlayer();
      await this.fetchKagetoraData();
    },
    async fetchPlayer() {
      try {
        let { data: player, error } = await supabase
          .from("players")
          .select("*, current_club:clubs (name)")
          .eq("id", this.id)
          .single();
        if (error) throw error;
        this.player = {
          ...player,
          current_club_name: player.current_club.name,
        };
        this.playerForm = {
          last_name: this.player.last_name,
          first_name: this.player.first_name,
          current_club_name: this.player.current_club_name,
          dominant_hand: this.player.dominant_hand,
          hand_notes: this.player.hand_notes,
        };
      } catch (error) {
        console.error("Error fetching player:", error.message);
      }
    },
    async fetchKagetoraData() {
      try {
        const url = "https://kagetora-api-wrapper.vercel.app/records";
        const base64Credentials = btoa("kagetora:Congelazione");

        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: "Basic " + base64Credentials,
          },
          // credentials: "include",
          body: JSON.stringify({
            name: this.player.last_name + this.player.first_name,
            no_aggr: false,
            page: 1,
            span: "full",
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        this.kagetoraData = await response.json();
        console.log(this.kagetoraData);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    },
    async fetchClubs() {
      try {
        let { data: clubs, error } = await supabase.from("clubs").select("id, name");
        if (error) throw error;
        this.clubOptions = clubs.map((club) => ({ value: club.id, text: club.name }));
      } catch (error) {
        console.error("Error fetching clubs:", error.message);
      }
    },
    async fetchComments() {
      try {
        let { data: comments, error } = await supabase
          .from("comments")
          .select("*")
          .eq("player_id", this.id)
          .order("created_at", { ascending: false });
        if (error) throw error;
        this.comments = comments;
      } catch (error) {
        console.error("Error fetching comments:", error.message);
      }
    },
    mergeData() {
      const comments = this.comments.map((comment) => ({
        type: "comment",
        content: comment.content,
        poster_name: comment.poster_name,
        created_at: comment.created_at,
        id: comment.id,
      }));

      const gamesByEvent = Object.values(this.kagetoraData.results.games).reduce((acc, event) => {
        Object.values(event)
          .filter((game) => game.result !== "break") // "break"のものを除外
          .forEach((game) => {
            if (!acc[game.event_id]) {
              acc[game.event_id] = {
                type: "game",
                event_id: game.event_id,
                event_name: this.kagetoraData.results.events[game.event_id].name,
                created_at: this.kagetoraData.results.events[game.event_id].date,
                games: [],
                id: game.event_id, // event_idをidとして設定
              };
            }
            acc[game.event_id].games.push(game);
          });
        return acc;
      }, {});

      const games = Object.values(gamesByEvent);

      const combinedData = [...comments, ...games].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      this.combinedData = combinedData;
    },
    saveDraft() {
      const draftKey = `draft_${this.id}`;
      localStorage.setItem(draftKey, this.newComment.content);
      this.hasDraft = true;
    },
    loadDraft() {
      const draftKey = `draft_${this.id}`;
      const savedDraft = localStorage.getItem(draftKey);
      if (savedDraft) {
        this.newComment.content = savedDraft;
      }
    },
    clearDraft() {
      const draftKey = `draft_${this.id}`;
      localStorage.removeItem(draftKey);
      this.hasDraft = false;
    },
    toggleCommentForm() {
      this.showCommentForm = !this.showCommentForm;
      if (this.showCommentForm) {
        this.$nextTick(() => {
          const element = document.getElementById("comment-form");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        });
      }
    },
    cancelComment() {
      if (this.newComment.content) {
        if (confirm("入力中のコメントがあります。キャンセルしてもよろしいですか？")) {
          this.newComment.content = "";
          this.clearDraft();
          this.showCommentForm = false;
        }
      } else {
        this.showCommentForm = false;
      }
    },
    async addComment() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        const userId = user ? user.uid : "anonymous";
        const posterName = this.selectedUser ? this.selectedUser.name : "匿名";

        const now = dayjs().tz("Asia/Tokyo");

        let { error } = await supabase.from("comments").insert([
          {
            player_id: this.id,
            user_id: userId,
            content: this.newComment.content,
            poster_name: posterName,
            created_at: now.toISOString(),
            updated_at: now.toISOString(),
          },
        ]);
        if (error) throw error;

        // 後処理
        this.fetchComments();
        this.newComment.content = "";
        this.clearDraft();
        this.showCommentForm = false;
      } catch (error) {
        console.error("Error adding comment:", error.message);
      }
    },
    editComment(comment) {
      this.editCommentForm.id = comment.id;
      this.editCommentForm.content = comment.content;
      this.isEditCommentModalVisible = true;
    },
    async updateComment() {
      try {
        const now = dayjs().tz("Asia/Tokyo");

        let { error } = await supabase
          .from("comments")
          .update({
            content: this.editCommentForm.content,
            updated_at: now.toISOString(),
          })
          .eq("id", this.editCommentForm.id);
        if (error) throw error;
        this.fetchComments();
        this.isEditCommentModalVisible = false;
      } catch (error) {
        console.error("Error updating comment:", error.message);
      }
    },
    confirmDeleteComment(comment) {
      this.deleteCommentId = comment.id;
      this.isDeleteCommentConfirmVisible = true;
    },
    async deleteComment() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        const userId = user ? user.uid : "anonymous";

        let { error } = await supabase.from("comments").delete().eq("id", this.deleteCommentId).eq("user_id", userId);
        if (error) throw error;
        this.fetchComments();
        this.isDeleteCommentConfirmVisible = false;
      } catch (error) {
        console.error("Error deleting comment:", error.message);
      }
    },
    selectUser(userId) {
      const database = getDatabase();
      const userProfileRef = ref(database, "users/" + userId);
      onValue(userProfileRef, (snapshot) => {
        this.selectedUser = snapshot.val();
      });
    },
    formatDate(date, format = "YYYY/MM/DD HH:mm:ss") {
      return dayjs(date).tz("Asia/Tokyo").format(format);
    },
    showEditPlayerModal() {
      this.isEditPlayerModalVisible = true;
    },
    async updatePlayer() {
      try {
        let currentClubId = this.getClubIdByName(this.playerForm.current_club_name);

        if (!currentClubId) {
          const { data, error } = await supabase
            .from("clubs")
            .insert([{ name: this.playerForm.current_club_name }])
            .select();

          if (error) throw error;
          currentClubId = data[0].id;
          this.fetchClubs(); // Update the club options
        }

        const now = dayjs().tz("Asia/Tokyo");

        let { error } = await supabase
          .from("players")
          .update({
            last_name: this.playerForm.last_name,
            first_name: this.playerForm.first_name,
            current_club_id: currentClubId,
            dominant_hand: this.playerForm.dominant_hand,
            hand_notes: this.playerForm.hand_notes,
            updated_at: now.toISOString(),
          })
          .eq("id", this.id);

        if (error) throw error;

        await this.loadPlayerData();
        await this.fetchComments();
        await this.fetchClubs();
        this.mergeData();

        this.isEditPlayerModalVisible = false;
      } catch (error) {
        console.error("Error updating player:", error.message);
      }
    },
    showDeletePlayerModal() {
      this.isDeletePlayerModalVisible = true;
    },
    async deletePlayer() {
      try {
        if (this.deletePlayerPassword === "your-password-here") {
          let { error } = await supabase.from("players").delete().eq("id", this.id);
          if (error) throw error;
          this.$router.push("/players");
        } else {
          alert("パスワードが間違っています。");
        }
      } catch (error) {
        console.error("Error deleting player:", error.message);
      }
    },
    resetPlayerForm() {
      this.playerForm = {
        last_name: this.player.last_name,
        first_name: this.player.first_name,
        current_club_name: this.player.current_club_name,
      };
    },
    resetDeletePlayerForm() {
      this.deletePlayerPassword = "";
    },
    getClubIdByName(name) {
      const club = this.clubOptions.find((club) => club.text === name);
      return club ? club.value : null;
    },
  },
};
</script>

<style scoped>
.comment-text {
  white-space: pre-wrap;
}

.container {
  margin-bottom: 64px;
}

.comment-actions {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.text-right {
  text-align: right;
}

.player-card {
  background-color: #f8f9fa;
  border-left: 4px solid #6c757d;
}

@media (max-width: 767.98px) {
  .player-card .d-flex {
    flex-direction: column;
    align-items: flex-start;
  }

  .player-card .d-flex div {
    margin-top: 1rem;
  }
}

.game-result-table {
  width: 100%;
  border-collapse: collapse;
}

.round,
.opponent,
.result {
  padding: 0.5em 1em;
}

.round {
  width: 80px;
  font-weight: bold;
}

.opponent {
  width: 120px;
}

.result {
  text-align: left;
  white-space: nowrap;
  width: auto;
}
</style>